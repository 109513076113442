import moment from 'moment';

const $countdowns = document.querySelectorAll('.js-countdown');

const init = () => {
    const setCountdown = ($item) => {
        const $value = $item.querySelector('.c-countdown__value');
        const endTime = moment($value.innerHTML);

        const x = setInterval(function() {
            const now = moment();

            let days = endTime.diff(now, 'days');
            let hours = endTime.diff(now, 'hours');
            let minutes = endTime.diff(now, 'minutes') % 60;

            if (days < 0 || hours < 0 || minutes < 0) {
                clearInterval(x);
                days = 0;
                hours = 0;
                minutes = 0;
            }

            $value.innerHTML = '';

            if (days != 0) {
                $value.innerHTML += `<span class="c-countdown__dd">${days}</span>`;
            }

            $value.innerHTML += `<span class="c-countdown__hh">${hours}</span>`;
            $value.innerHTML += `<span class="c-countdown__mm">${minutes}</span>`;
            $item.classList.add('is-visible');
        }, 1000);
    };

    if ($countdowns.length > 0) {
        for (var i = 0; i < $countdowns.length; i++) {
            setCountdown($countdowns[i]);
        }
    }
}

export default { init }
