import M from 'materialize-css';
import t from '../../lang';

const init = () => {
    const datepickers = document.querySelectorAll('input.datepicker');

    if (datepickers) {
        for (let i = 0; i < datepickers.length; i++) {
            M.Datepicker.init(datepickers[i], {
                autoClose: true,
                format: 'dd/mm/yyyy',
                setDefaultDate: true,
                i18n: t('material').datepicker
            });
        }
    }
}

export default { init }
