import M from 'materialize-css';

const init = () => {
    const modals = document.querySelectorAll('.modal:not(.datepicker-modal)');

    if (modals) {
        M.Modal.init(modals);
    }
}

export default { init }
