import M from 'materialize-css';

const $sideMenu = document.querySelector('.js-sidemenu');

let sideNav = null;

if ($sideMenu) {
  sideNav = M.Sidenav.init($sideMenu.querySelector('#aside-menu'), {
    edge: 'left',
    draggable: false,
    preventScrolling: true
  });
}

export default sideNav;
