import M from 'materialize-css';
import { isMobile } from "react-device-detect";

const init = () => {
    if (!isMobile) {
        const tooltips = document.querySelectorAll('.tooltipped');

        if (tooltips) {
            M.Tooltip.init(tooltips, {
                exitDelay: 0,
                enterDelay: 0
            });
        }
    }
}

export default { init }
