import Collapsible from './collapsible';
import Countdown from './countdown';
import Currency from './form/currency';
import Datepicker from './form/datepicker';
import DeleteListItem from './deleteListItem';
import Header from './header';
import Modals from './modals';
import PreviewListItem from './previewListItem';
import Select from './form/select';
import Tabs from './tabs';
import Tags from './form/tags';
import Textarea from './form/textarea';
import ToggleInput from './form/toggleInput';
import Tooltips from './tooltips';

export default {
    Collapsible,
    Countdown,
    Currency,
    Datepicker,
    DeleteListItem,
    Header,
    Modals,
    PreviewListItem,
    Select,
    Tabs,
    Tags,
    Textarea,
    ToggleInput,
    Tooltips
}
