import M from 'materialize-css';

const init = () => {
    const tagList = document.querySelectorAll('.js-tags');

    if (tagList.length > 0) {
        for (let i = 0; i < tagList.length; i++) {
            let instance = null;

            const name = tagList[i].getAttribute('data-name')
            const $chips = tagList[i].querySelector('.chips')
            const $inputValues = tagList[i].querySelector('.tag-values')

            const generateInputArray = () => {
                $inputValues.innerHTML = '';

                for (let i = 0; i < instance.chipsData.length; i++) {
                    const input = document.createElement("input");
                    input.setAttribute("type", "hidden");
                    input.setAttribute("name", `${name}[]`);
                    input.setAttribute("value", instance.chipsData[i].tag);
                    $inputValues.appendChild(input);
                }
            };

            const options = {
                placeholder: $chips.getAttribute('data-placeholder'),
                secondaryPlaceholder: $chips.getAttribute('data-secondaryPlaceholder'),
                onChipAdd: generateInputArray,
                onChipDelete: generateInputArray
            };

            const data = []
            const $initValues = $inputValues.querySelectorAll('input[type=hidden]')

            if ($initValues.length > 0) {
                for (let i = 0; i < $initValues.length; i++) {
                    data.push({ tag: $initValues[i].value });
                }

                options['data'] = data;
            }

            instance = M.Chips.init($chips, options);
        }
    }
}

export default { init }
