import M from 'materialize-css';

const init = () => {
    const $deleteForms = document.querySelectorAll('.js-deleteListItem');

    let $deleteModal = null;
    let $deleteModalButton = null;
    let $deleteModalMessage = null;
    let deleteModalInstance = null;
    let isModalOpen = false;

    const onDeleteButtonClick = (e) => {
        e.target.formRef.submit();
    };

    const onSubmitHandler = (e) => {
        if (!isModalOpen) {
            e.preventDefault();

            if ($deleteModal) {
                if ($deleteModalMessage) {
                    if (e.target.classList.contains('hide-modal-message')) {
                        $deleteModalMessage.classList.add('hide');
                    } else {
                        $deleteModalMessage.classList.remove('hide');
                    }
                }

                deleteModalInstance.open();
            }

            $deleteModalButton.addEventListener('click', onDeleteButtonClick);
            $deleteModalButton.formRef = e.target;
        }
    }

    const onModalOpenStart = () => {
        isModalOpen = true;
    };

    const onModalCloseStart = () => {
        $deleteModalButton.removeEventListener('click', onDeleteButtonClick);
        $deleteModalButton.formRef = null;
        isModalOpen = false;
    };

    const onLoadHandler = () => {
        $deleteModal = document.getElementById('modal-delete-list-item');
        $deleteModalButton = $deleteModal.querySelector('.is-confirm');
        $deleteModalMessage = $deleteModal.querySelector('.modal-message');
        deleteModalInstance = M.Modal.getInstance($deleteModal);

        deleteModalInstance.options.onOpenStart = onModalOpenStart;
        deleteModalInstance.options.onCloseStart = onModalCloseStart;

        for (var i = 0; i < $deleteForms.length; i++) {
            $deleteForms[i].addEventListener('submit', onSubmitHandler);
        }
    };

    if ($deleteForms.length > 0) {
        window.addEventListener('load', onLoadHandler);
    }
}

export default { init }
