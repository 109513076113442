import Cleave from 'cleave.js';
import M from 'materialize-css';

const init = () => {
    const inputs = document.querySelectorAll('input[currency]');

    for (var i = 0; i < inputs.length; i++) {
        new Cleave(inputs[i], {
            numeral: true,
            numeralPositiveOnly: 2,
            numeralPositiveOnly: true,
            numeralThousandsGroupStyle: 'thousand',
            numeralDecimalMark: ',',
            delimiter: '.',
            prefix: '€ '
        });

        M.updateTextFields();
    }
}

export default { init }
