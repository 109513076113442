import M from 'materialize-css';

const init = () => {
    const collapsibles = document.querySelectorAll('.js-collapsible');

    if (collapsibles) {
        M.Collapsible.init(collapsibles, {
            exitDelay: 0,
            enterDelay: 0
        });
    }
}

export default { init }
