import M from 'materialize-css';

const init = () => {
    const selects = document.querySelectorAll('.js-select select');

    if (selects) {
        for (let i = 0; i < selects.length; i++) {
            const placeholder = selects[i].querySelector('option[value=""]');

            if (placeholder) {
                placeholder.setAttribute('disabled', true);
            }
        }

        M.FormSelect.init(selects);
    }
}

export default { init }
