import M from 'materialize-css';

const init = () => {
    const tabs = document.querySelectorAll('.tabs');

    if (tabs) {
        for (var i = 0; i < tabs.length; i++) {
            M.Tabs.init(tabs[i]);
        }
    }
}

export default { init }
