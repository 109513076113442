const lang_it = require('../lang/it_IT.json');

const trans = (message) => {
    if (document.documentElement.lang === 'it-IT') {
        if (lang_it[message]) {
            return lang_it[message]
        }
    }

    return message;
};

export const transMonthsShort = (month) => {
    return lang_it["material"]["datepicker"]["monthsShort"][month-1];
};

export default trans;
