import M from 'materialize-css';
import SideMenu from './sideMenu';

const init = () => {
    const $header = document.querySelector('.js-header');

    const initDropdowns = () => {
        const $dropdowns = $header.querySelectorAll('.dropdown-trigger');

        if ($dropdowns) {
            M.Dropdown.init($dropdowns, {
                coverTrigger: false,
                alignment: 'right',
                closeOnClick: false
            });
        }
    };

    const initSideMenuToggle = () => {
        const $toggle = $header.querySelector('.c-header__toggle');

        if ($toggle) {
            SideMenu.options.onOpenStart = () => {
                $toggle.classList.add('is-toggle');
            };

            SideMenu.options.onCloseStart = () => {
                $toggle.classList.remove('is-toggle');
            };

            const onClickHandler = () => {
                if (SideMenu.isOpen) {
                    SideMenu.close();
                } else {
                    SideMenu.open();
                }
            };

            $toggle.addEventListener('click', onClickHandler);
        }
    };

    if ($header) {
        initDropdowns();

        if (SideMenu) {
            initSideMenuToggle();
        }
    }
}

export default { init }
