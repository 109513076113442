import M from 'materialize-css';

const init = () => {
    const $previewListItem = document.querySelectorAll('.js-previewListItem');

    let $previewModal = null;

    const onClickHandler = (e) => {
        e.preventDefault();

        const $clicked = e.target;
        const newModalChild = $clicked.querySelector('.preview-modal-child');

        $previewModal.querySelector('.modal-content').innerHTML = newModalChild.innerHTML;
    };

    const onLoadHandler = () => {
        $previewModal = document.getElementById('preview-modal');

        for (var i = 0; i < $previewListItem.length; i++) {
            $previewListItem[i].addEventListener('click', onClickHandler);
        }
    };

    if ($previewListItem.length > 0) {
        window.addEventListener('load', onLoadHandler);
    }
}

export default { init }
