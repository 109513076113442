const apply = (wrapper) => {
    const $select = wrapper.querySelector('.toggle');
    const $toggles = wrapper.querySelectorAll('[data-toggle]');

    for (var i = 0; i < $toggles.length; i++) {
        $toggles[i].closest('.input-field').classList.add('hide');
    }

    $select.addEventListener('change', (e) => {
        const $found = Array.from($toggles).find($item => {
            return $item.getAttribute('data-toggle') == e.target.value;
        });

        if ($found) {
            $found.closest('.input-field').classList.remove('hide');
        } else {
            for (var i = 0; i < $toggles.length; i++) {
                $toggles[i].closest('.input-field').classList.add('hide');
            }
        }
    });
}

const init = () => {
    const toggleBoxes = document.querySelectorAll('.js-toggleInput');

    if (toggleBoxes) {
        for (let i = 0; i < toggleBoxes.length; i++) {
            apply(toggleBoxes[i]);
        }
    }
}

export default { init }
