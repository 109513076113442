import M from 'materialize-css';

const init = () => {
    const textareas = document.querySelectorAll('.js-textarea');

    const fixHeight = () => {
        for (let i = 0; i < textareas.length; i++) {
            M.textareaAutoResize(textareas[i]);
        }
    };

    if (textareas) {
        window.addEventListener('resize', fixHeight);
    }
}

export default { init }
